import { CookieVerificationValues } from '../../config/cookieConfig'
import {
    SET_COOKIE_POLICY,
    SET_SHOW_COOKIE_POLICY_MODAL,
    SET_IP_RESOLVED,
} from '../types'

export const cookiePolicyKey = 'cookiePolicy'

export const initialState = {
    [cookiePolicyKey]: {
        showCookiePolicy: false,
        status: CookieVerificationValues.unknown || 'unknown',
        ipResolved: false,
    },
}

const CookiePolicyReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COOKIE_POLICY:
            return {
                ...state,
                [cookiePolicyKey]: {
                    ...state[cookiePolicyKey],
                    status: action.payload,
                },
            }

        case SET_SHOW_COOKIE_POLICY_MODAL:
            return {
                ...state,
                [cookiePolicyKey]: {
                    ...state[cookiePolicyKey],
                    showCookiePolicy: action.payload,
                },
            }

        case SET_IP_RESOLVED:
            return {
                ...state,
                [cookiePolicyKey]: {
                    ...state[cookiePolicyKey],
                    ipResolved: action.payload,
                },
            }

        default:
            return { ...state }
    }
}

export default CookiePolicyReducer
