exports.components = {
  "component---src-pages-404-demo-cancelled-by-user-js": () => import("./../../../src/pages/404-demo-cancelled-by-user.js" /* webpackChunkName: "component---src-pages-404-demo-cancelled-by-user-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-already-registered-js": () => import("./../../../src/pages/already-registered.js" /* webpackChunkName: "component---src-pages-already-registered-js" */),
  "component---src-pages-au-ab-components-accordion-accordion-jsx": () => import("./../../../src/pages/au/ab/components/accordion/accordion.jsx" /* webpackChunkName: "component---src-pages-au-ab-components-accordion-accordion-jsx" */),
  "component---src-pages-au-ab-components-banner-banner-jsx": () => import("./../../../src/pages/au/ab/components/Banner/banner.jsx" /* webpackChunkName: "component---src-pages-au-ab-components-banner-banner-jsx" */),
  "component---src-pages-au-ab-components-book-demo-form-index-js": () => import("./../../../src/pages/au/ab/components/book-demo-form/index.js" /* webpackChunkName: "component---src-pages-au-ab-components-book-demo-form-index-js" */),
  "component---src-pages-au-ab-components-bottom-section-bottom-section-jsx": () => import("./../../../src/pages/au/ab/components/bottom-section/bottom-section.jsx" /* webpackChunkName: "component---src-pages-au-ab-components-bottom-section-bottom-section-jsx" */),
  "component---src-pages-au-ab-components-footer-jsx": () => import("./../../../src/pages/au/ab/components/footer.jsx" /* webpackChunkName: "component---src-pages-au-ab-components-footer-jsx" */),
  "component---src-pages-au-ab-components-login-login-jsx": () => import("./../../../src/pages/au/ab/components/login/login.jsx" /* webpackChunkName: "component---src-pages-au-ab-components-login-login-jsx" */),
  "component---src-pages-au-ab-components-middlesection-middlesection-jsx": () => import("./../../../src/pages/au/ab/components/middlesection/middlesection.jsx" /* webpackChunkName: "component---src-pages-au-ab-components-middlesection-middlesection-jsx" */),
  "component---src-pages-au-ab-components-modal-book-free-trial-modal-jsx": () => import("./../../../src/pages/au/ab/components/Modal/book-free-trial-modal.jsx" /* webpackChunkName: "component---src-pages-au-ab-components-modal-book-free-trial-modal-jsx" */),
  "component---src-pages-au-ab-components-navbar-exit-jsx": () => import("./../../../src/pages/au/ab/components/navbar/exit.jsx" /* webpackChunkName: "component---src-pages-au-ab-components-navbar-exit-jsx" */),
  "component---src-pages-au-ab-components-navbar-navbar-jsx": () => import("./../../../src/pages/au/ab/components/navbar/navbar.jsx" /* webpackChunkName: "component---src-pages-au-ab-components-navbar-navbar-jsx" */),
  "component---src-pages-au-ab-components-slider-originalslider-jsx": () => import("./../../../src/pages/au/ab/components/slider/originalslider.jsx" /* webpackChunkName: "component---src-pages-au-ab-components-slider-originalslider-jsx" */),
  "component---src-pages-au-ab-components-topcontent-topcontent-jsx": () => import("./../../../src/pages/au/ab/components/topcontent/topcontent.jsx" /* webpackChunkName: "component---src-pages-au-ab-components-topcontent-topcontent-jsx" */),
  "component---src-pages-au-ab-index-js": () => import("./../../../src/pages/au/ab/index.js" /* webpackChunkName: "component---src-pages-au-ab-index-js" */),
  "component---src-pages-au-mm-index-js": () => import("./../../../src/pages/au/mm/index.js" /* webpackChunkName: "component---src-pages-au-mm-index-js" */),
  "component---src-pages-book-demo-js": () => import("./../../../src/pages/book-demo.js" /* webpackChunkName: "component---src-pages-book-demo-js" */),
  "component---src-pages-book-demo-premium-js": () => import("./../../../src/pages/book-demo-premium.js" /* webpackChunkName: "component---src-pages-book-demo-premium-js" */),
  "component---src-pages-cancel-session-js": () => import("./../../../src/pages/cancel-session.js" /* webpackChunkName: "component---src-pages-cancel-session-js" */),
  "component---src-pages-chess-components-about-our-author-about-bhanu-js": () => import("./../../../src/pages/chess/components/AboutOurAuthor/AboutBhanu.js" /* webpackChunkName: "component---src-pages-chess-components-about-our-author-about-bhanu-js" */),
  "component---src-pages-chess-components-about-our-author-achievements-js": () => import("./../../../src/pages/chess/components/AboutOurAuthor/Achievements.js" /* webpackChunkName: "component---src-pages-chess-components-about-our-author-achievements-js" */),
  "component---src-pages-chess-components-about-our-author-index-js": () => import("./../../../src/pages/chess/components/AboutOurAuthor/index.js" /* webpackChunkName: "component---src-pages-chess-components-about-our-author-index-js" */),
  "component---src-pages-chess-components-course-details-batch-js": () => import("./../../../src/pages/chess/components/CourseDetails/Batch.js" /* webpackChunkName: "component---src-pages-chess-components-course-details-batch-js" */),
  "component---src-pages-chess-components-course-details-batches-js": () => import("./../../../src/pages/chess/components/CourseDetails/Batches.js" /* webpackChunkName: "component---src-pages-chess-components-course-details-batches-js" */),
  "component---src-pages-chess-components-course-details-grades-js": () => import("./../../../src/pages/chess/components/CourseDetails/Grades.js" /* webpackChunkName: "component---src-pages-chess-components-course-details-grades-js" */),
  "component---src-pages-chess-components-course-details-index-js": () => import("./../../../src/pages/chess/components/CourseDetails/index.js" /* webpackChunkName: "component---src-pages-chess-components-course-details-index-js" */),
  "component---src-pages-chess-components-course-details-pricing-js": () => import("./../../../src/pages/chess/components/CourseDetails/Pricing.js" /* webpackChunkName: "component---src-pages-chess-components-course-details-pricing-js" */),
  "component---src-pages-chess-components-course-syllabus-js": () => import("./../../../src/pages/chess/components/CourseSyllabus.js" /* webpackChunkName: "component---src-pages-chess-components-course-syllabus-js" */),
  "component---src-pages-chess-components-enroll-button-js": () => import("./../../../src/pages/chess/components/EnrollButton.js" /* webpackChunkName: "component---src-pages-chess-components-enroll-button-js" */),
  "component---src-pages-chess-components-explore-our-course-button-js": () => import("./../../../src/pages/chess/components/ExploreOurCourseButton.js" /* webpackChunkName: "component---src-pages-chess-components-explore-our-course-button-js" */),
  "component---src-pages-chess-components-featured-js": () => import("./../../../src/pages/chess/components/Featured.js" /* webpackChunkName: "component---src-pages-chess-components-featured-js" */),
  "component---src-pages-chess-components-first-section-js": () => import("./../../../src/pages/chess/components/FirstSection.js" /* webpackChunkName: "component---src-pages-chess-components-first-section-js" */),
  "component---src-pages-chess-components-footer-js": () => import("./../../../src/pages/chess/components/Footer.js" /* webpackChunkName: "component---src-pages-chess-components-footer-js" */),
  "component---src-pages-chess-components-general-info-js": () => import("./../../../src/pages/chess/components/GeneralInfo.js" /* webpackChunkName: "component---src-pages-chess-components-general-info-js" */),
  "component---src-pages-chess-components-header-js": () => import("./../../../src/pages/chess/components/Header.js" /* webpackChunkName: "component---src-pages-chess-components-header-js" */),
  "component---src-pages-chess-components-payment-details-modal-js": () => import("./../../../src/pages/chess/components/PaymentDetailsModal.js" /* webpackChunkName: "component---src-pages-chess-components-payment-details-modal-js" */),
  "component---src-pages-chess-components-vpn-modal-js": () => import("./../../../src/pages/chess/components/VpnModal.js" /* webpackChunkName: "component---src-pages-chess-components-vpn-modal-js" */),
  "component---src-pages-chess-components-what-is-mathlete-js": () => import("./../../../src/pages/chess/components/WhatIsMathlete.js" /* webpackChunkName: "component---src-pages-chess-components-what-is-mathlete-js" */),
  "component---src-pages-chess-register-js": () => import("./../../../src/pages/chess/register.js" /* webpackChunkName: "component---src-pages-chess-register-js" */),
  "component---src-pages-demo-booking-failed-js": () => import("./../../../src/pages/demo-booking-failed.js" /* webpackChunkName: "component---src-pages-demo-booking-failed-js" */),
  "component---src-pages-demo-booking-thank-you-js": () => import("./../../../src/pages/demo-booking-thank-you.js" /* webpackChunkName: "component---src-pages-demo-booking-thank-you-js" */),
  "component---src-pages-english-demo-booking-thank-you-js": () => import("./../../../src/pages/english-demo-booking-thank-you.js" /* webpackChunkName: "component---src-pages-english-demo-booking-thank-you-js" */),
  "component---src-pages-english-select-slot-js": () => import("./../../../src/pages/english-select-slot.js" /* webpackChunkName: "component---src-pages-english-select-slot-js" */),
  "component---src-pages-english-topic-selection-js": () => import("./../../../src/pages/english-topic-selection.js" /* webpackChunkName: "component---src-pages-english-topic-selection-js" */),
  "component---src-pages-english-verify-otp-js": () => import("./../../../src/pages/english-verify-otp.js" /* webpackChunkName: "component---src-pages-english-verify-otp-js" */),
  "component---src-pages-extra-class-hobby-js": () => import("./../../../src/pages/extraClass/hobby.js" /* webpackChunkName: "component---src-pages-extra-class-hobby-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-english-info-page-js": () => import("./../../../src/pages/learn-english/info-page.js" /* webpackChunkName: "component---src-pages-learn-english-info-page-js" */),
  "component---src-pages-learn-english-register-js": () => import("./../../../src/pages/learn-english/register.js" /* webpackChunkName: "component---src-pages-learn-english-register-js" */),
  "component---src-pages-math-olympiad-components-about-course-js": () => import("./../../../src/pages/mathOlympiad/components/AboutCourse.js" /* webpackChunkName: "component---src-pages-math-olympiad-components-about-course-js" */),
  "component---src-pages-math-olympiad-components-bhanzu-quote-js": () => import("./../../../src/pages/mathOlympiad/components/BhanzuQuote.js" /* webpackChunkName: "component---src-pages-math-olympiad-components-bhanzu-quote-js" */),
  "component---src-pages-math-olympiad-components-check-our-curriculum-js": () => import("./../../../src/pages/mathOlympiad/components/CheckOurCurriculum.js" /* webpackChunkName: "component---src-pages-math-olympiad-components-check-our-curriculum-js" */),
  "component---src-pages-math-olympiad-components-course-content-js": () => import("./../../../src/pages/mathOlympiad/components/CourseContent.js" /* webpackChunkName: "component---src-pages-math-olympiad-components-course-content-js" */),
  "component---src-pages-math-olympiad-components-course-details-js": () => import("./../../../src/pages/mathOlympiad/components/CourseDetails.js" /* webpackChunkName: "component---src-pages-math-olympiad-components-course-details-js" */),
  "component---src-pages-math-olympiad-components-enroll-button-js": () => import("./../../../src/pages/mathOlympiad/components/EnrollButton.js" /* webpackChunkName: "component---src-pages-math-olympiad-components-enroll-button-js" */),
  "component---src-pages-math-olympiad-components-enroll-form-js": () => import("./../../../src/pages/mathOlympiad/components/EnrollForm.js" /* webpackChunkName: "component---src-pages-math-olympiad-components-enroll-form-js" */),
  "component---src-pages-math-olympiad-components-math-olympiad-banner-js": () => import("./../../../src/pages/mathOlympiad/components/MathOlympiadBanner.js" /* webpackChunkName: "component---src-pages-math-olympiad-components-math-olympiad-banner-js" */),
  "component---src-pages-math-olympiad-components-select-grade-js": () => import("./../../../src/pages/mathOlympiad/components/SelectGrade.js" /* webpackChunkName: "component---src-pages-math-olympiad-components-select-grade-js" */),
  "component---src-pages-math-olympiad-course-details-js": () => import("./../../../src/pages/mathOlympiad/course-details.js" /* webpackChunkName: "component---src-pages-math-olympiad-course-details-js" */),
  "component---src-pages-math-olympiad-index-js": () => import("./../../../src/pages/mathOlympiad/index.js" /* webpackChunkName: "component---src-pages-math-olympiad-index-js" */),
  "component---src-pages-mathlete-101-free-trial-register-js": () => import("./../../../src/pages/mathlete101/free-trial-register.js" /* webpackChunkName: "component---src-pages-mathlete-101-free-trial-register-js" */),
  "component---src-pages-mathlete-101-old-components-about-js": () => import("./../../../src/pages/mathlete101_old/components/about.js" /* webpackChunkName: "component---src-pages-mathlete-101-old-components-about-js" */),
  "component---src-pages-mathlete-101-old-components-banner-live-stream-js": () => import("./../../../src/pages/mathlete101_old/components/BannerLiveStream.js" /* webpackChunkName: "component---src-pages-mathlete-101-old-components-banner-live-stream-js" */),
  "component---src-pages-mathlete-101-old-components-content-card-js": () => import("./../../../src/pages/mathlete101_old/components/contentCard.js" /* webpackChunkName: "component---src-pages-mathlete-101-old-components-content-card-js" */),
  "component---src-pages-mathlete-101-old-components-course-details-js": () => import("./../../../src/pages/mathlete101_old/components/courseDetails.js" /* webpackChunkName: "component---src-pages-mathlete-101-old-components-course-details-js" */),
  "component---src-pages-mathlete-101-old-components-course-pricing-js": () => import("./../../../src/pages/mathlete101_old/components/CoursePricing.js" /* webpackChunkName: "component---src-pages-mathlete-101-old-components-course-pricing-js" */),
  "component---src-pages-mathlete-101-old-components-course-pricing-old-js": () => import("./../../../src/pages/mathlete101_old/components/CoursePricingOld.js" /* webpackChunkName: "component---src-pages-mathlete-101-old-components-course-pricing-old-js" */),
  "component---src-pages-mathlete-101-old-components-faq-section-js": () => import("./../../../src/pages/mathlete101_old/components/faqSection.js" /* webpackChunkName: "component---src-pages-mathlete-101-old-components-faq-section-js" */),
  "component---src-pages-mathlete-101-old-components-key-course-content-component-js": () => import("./../../../src/pages/mathlete101_old/components/keyCourseContentComponent.js" /* webpackChunkName: "component---src-pages-mathlete-101-old-components-key-course-content-component-js" */),
  "component---src-pages-mathlete-101-old-components-key-course-content-section-js": () => import("./../../../src/pages/mathlete101_old/components/keyCourseContentSection.js" /* webpackChunkName: "component---src-pages-mathlete-101-old-components-key-course-content-section-js" */),
  "component---src-pages-mathlete-101-old-components-mathlete-enroll-button-jsx": () => import("./../../../src/pages/mathlete101_old/components/MathleteEnrollButton.jsx" /* webpackChunkName: "component---src-pages-mathlete-101-old-components-mathlete-enroll-button-jsx" */),
  "component---src-pages-mathlete-101-old-course-details-js": () => import("./../../../src/pages/mathlete101_old/course-details.js" /* webpackChunkName: "component---src-pages-mathlete-101-old-course-details-js" */),
  "component---src-pages-mathlete-101-old-register-js": () => import("./../../../src/pages/mathlete101_old/register.js" /* webpackChunkName: "component---src-pages-mathlete-101-old-register-js" */),
  "component---src-pages-mathlete-101-payment-success-js": () => import("./../../../src/pages/mathlete101/payment-success.js" /* webpackChunkName: "component---src-pages-mathlete-101-payment-success-js" */),
  "component---src-pages-mathlete-101-register-js": () => import("./../../../src/pages/mathlete101/register.js" /* webpackChunkName: "component---src-pages-mathlete-101-register-js" */),
  "component---src-pages-mathlete-101-user-info-js": () => import("./../../../src/pages/mathlete101/user-info.js" /* webpackChunkName: "component---src-pages-mathlete-101-user-info-js" */),
  "component---src-pages-mathmarvel-index-js": () => import("./../../../src/pages/mathmarvel/index.js" /* webpackChunkName: "component---src-pages-mathmarvel-index-js" */),
  "component---src-pages-not-started-js": () => import("./../../../src/pages/not-started.js" /* webpackChunkName: "component---src-pages-not-started-js" */),
  "component---src-pages-payment-status-js": () => import("./../../../src/pages/paymentStatus.js" /* webpackChunkName: "component---src-pages-payment-status-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-referral-invite-js": () => import("./../../../src/pages/referral-invite.js" /* webpackChunkName: "component---src-pages-referral-invite-js" */),
  "component---src-pages-referral-program-policy-js": () => import("./../../../src/pages/referral-program-policy.js" /* webpackChunkName: "component---src-pages-referral-program-policy-js" */),
  "component---src-pages-registration-failed-js": () => import("./../../../src/pages/registration-failed.js" /* webpackChunkName: "component---src-pages-registration-failed-js" */),
  "component---src-pages-rerferral-thank-you-js": () => import("./../../../src/pages/rerferral-thank-you.js" /* webpackChunkName: "component---src-pages-rerferral-thank-you-js" */),
  "component---src-pages-select-slot-js": () => import("./../../../src/pages/select-slot.js" /* webpackChunkName: "component---src-pages-select-slot-js" */),
  "component---src-pages-submit-details-js": () => import("./../../../src/pages/submit-details.js" /* webpackChunkName: "component---src-pages-submit-details-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-topic-selection-js": () => import("./../../../src/pages/topic-selection.js" /* webpackChunkName: "component---src-pages-topic-selection-js" */),
  "component---src-pages-uae-book-demo-js": () => import("./../../../src/pages/uae/book-demo.js" /* webpackChunkName: "component---src-pages-uae-book-demo-js" */),
  "component---src-pages-verify-otp-js": () => import("./../../../src/pages/verify-otp.js" /* webpackChunkName: "component---src-pages-verify-otp-js" */),
  "component---src-pages-webinar-book-webinar-js": () => import("./../../../src/pages/webinar/book-webinar.js" /* webpackChunkName: "component---src-pages-webinar-book-webinar-js" */),
  "component---src-pages-webinar-register-1-js": () => import("./../../../src/pages/webinar/register-1.js" /* webpackChunkName: "component---src-pages-webinar-register-1-js" */),
  "component---src-pages-webinar-register-2-js": () => import("./../../../src/pages/webinar/register-2.js" /* webpackChunkName: "component---src-pages-webinar-register-2-js" */),
  "component---src-templates-ad-page-template-js": () => import("./../../../src/templates/AdPageTemplate.js" /* webpackChunkName: "component---src-templates-ad-page-template-js" */),
  "component---src-templates-book-demo-template-js": () => import("./../../../src/templates/BookDemoTemplate.js" /* webpackChunkName: "component---src-templates-book-demo-template-js" */),
  "component---src-templates-social-media-page-template-js": () => import("./../../../src/templates/SocialMediaPageTemplate.js" /* webpackChunkName: "component---src-templates-social-media-page-template-js" */)
}

