export const websiteCookies = {
    country: 'country',
    timezone: 'timezone',
    city: 'city',
    region: 'region',
    postal: 'postal',
    locationVerified: 'locationVerified',
    authCookie: 'bhanzu-user-auth',
    cookiePolicy: 'cookiePolicy',
}

export const LocationVerificationValues = {
    Yes: 'Yes',
    No: 'No',
}

export const CookieVerificationValues = {
    unknown: 'unknown',
    accept: 'Accept All Cookies',
    reject: 'Reject All Cookies',
}
