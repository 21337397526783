import {
    SET_LOCATION_DETAILS,
    CLOSE_VPN_POPUP,
    SET_LOCATION_LOADED,
} from '../types'

export const locationDetailsKey = 'locationDetails'

export const initialState = {
    [locationDetailsKey]: {
        country: 'US',
        timezone: 'America/Los_Angeles',
        vpnPopupOpen: false,
        isLoaded: false,
    },
}

const LocationDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOCATION_DETAILS:
            return {
                ...state,
                [locationDetailsKey]: {
                    ...state[locationDetailsKey],
                    ...action.payload[locationDetailsKey],
                },
                ...action.payload,
            }

        case SET_LOCATION_LOADED:
            return {
                ...state,
                [locationDetailsKey]: {
                    ...state[locationDetailsKey],
                    isLoaded: true,
                },
            }

        case CLOSE_VPN_POPUP:
            return {
                ...state,
                [locationDetailsKey]: {
                    ...state[locationDetailsKey],
                    vpnPopupOpen: false,
                },
            }

        default:
            return { ...state }
    }
}

export default LocationDetailsReducer
