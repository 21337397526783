import { CurrentSession, GlobalSession } from '../../hooks/useSession'

import { MOE_INIT, TRACK_MOE_EVENT } from '../types'

const isBrowser = typeof window !== 'undefined'
const APP_ID = process.env.MONENGAGE_APP_ID
const CLUSTER_ID = process.env.MONENGAGE_CLUSTER_ID
const IS_MOE_ENABLED = process.env.MONENGAGE_ENABLED

let globalSessionId = null
let currentSessionId = null
if (isBrowser) {
    globalSessionId = GlobalSession()
    currentSessionId = CurrentSession()
}

export const moeDetailsKey = 'moe'

export const initialState = {
    [moeDetailsKey]: [],
}

const getUTMDS = () => {
    const utmDS =
        typeof window !== 'undefined'
            ? window.localStorage.getItem('utm-parms-ds')
            : '{}'
    return JSON.parse(utmDS)
}

const _publishEvent = (payload) => {
    const data = { ...(payload.data || {})}
    const regex = /(?!^.*)[^](?=.{3})/g;
    data.email_address && (data.email_address = data.email_address.replace(regex, '*'));
    data.mobile_number && (data.mobile_number = data.mobile_number.replace(regex, 'X'));
    data.otp_source && (data.otp_source    = data.otp_source.replace(regex, 'X'));
    data.student_name && (data.student_name = data.student_name.replace(regex, 'X'));

    getMoenageRef().track_event(payload.type, {
        ...data,
        B_GID: globalSessionId,
        B_SID: currentSessionId,
        LEAD_ID:
            typeof window !== 'undefined' &&
            window.localStorage.getItem('leadId'),
        IS_BOOKING_VIA_LP:
            typeof window !== 'undefined' &&
            window.localStorage.getItem('isViaLP'),
        PAGE_NAME:
            typeof window !== 'undefined' &&
            window.localStorage.getItem('pageName'),
        IS_EXPERIMENT:
            typeof window !== 'undefined' &&
            window.localStorage.getItem('is_experiment'),
        EXPERIMENT_ID:
            typeof window !== 'undefined' &&
            window.localStorage.getItem('experiment_id'),
        ...getUTMDS(),
    })
}

const isMoeLoaded = () => {
    return !!window.Moengage
}

const getMoenageRef = () => {
    return window.Moengage
}

const _publishAllFailedEvent = (payloads) => {
    payloads.forEach((payload) => {
        _publishEvent(payload)
    })
}

const loadMoengage = () => {
    if (!window.isMoeLoadingViaDI) {
        window.isMoeLoadingViaDI = true
        import('@moengage/web-sdk').then(({ default: moengage }) => {
            moengage.initialize({
                app_id: APP_ID,
                cluster: CLUSTER_ID,
            })
        })
    }
}

const MoengageReducer = (state = initialState, action) => {
    const { payload } = action
    const moeDetails = state[moeDetailsKey] ?? []
    switch (action.type) {
        case TRACK_MOE_EVENT:
            try {
                if (isBrowser && IS_MOE_ENABLED) {
                    if (isMoeLoaded()) {
                        _publishAllFailedEvent(moeDetails)
                        _publishEvent(payload)
                        state[moeDetailsKey] = []
                    } else {
                        loadMoengage()
                        throw new Error('Moengage not loaded, loading now')
                    }
                }
            } catch (e) {
                if (payload.type == MOE_INIT) {
                    console.log('Moengage loading now')
                } else {
                    moeDetails.push(payload)
                    state[moeDetailsKey] = moeDetails
                }
            }
            return {
                ...state,
            }
        default:
            return { ...state }
    }
}

export default MoengageReducer
