import {
    CookieVerificationValues,
    websiteCookies,
} from '../config/cookieConfig'
import UtilService from '../services/utilServices'

const isBrowser = typeof window !== 'undefined'
function gtag() {
    if (!isBrowser) return
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(arguments)
}

const sha256Hash = async (message) => {
    if (typeof TextEncoder === 'undefined') {
        return "TextEncoder is not supported in this environment"
    }

    if (typeof crypto === 'undefined' || !crypto.subtle || typeof crypto.subtle.digest !== 'function') {
        return "Web Crypto API is not supported in this environment"
    }
    const msgBuffer = new TextEncoder().encode(message);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
};

const getHash = async (message) => {
    const hash = await sha256Hash(message);
    return hash;
};


// ad_storage
// analytics_storage
// functionality_storage
// personalization_storage
// security_storage
// ad_user_data
// ad_personalization
export const addDataLayerRestriction = () => {
    if (!isBrowser) return
    // Set default consent to 'denied' as a placeholder
    // Determine actual values based on your own requirements

    const cookiePolicyValue = UtilService.getCookie(websiteCookies.cookiePolicy)
    if (cookiePolicyValue === CookieVerificationValues.accept) {
        gtag('consent', 'default', {
            ad_storage: 'granted',
            analytics_storage: 'granted',
            functionality_storage: 'granted',
            personalization_storage: 'granted',
            security_storage: 'granted',
            ad_user_data: 'granted',
            ad_personalization: 'granted',
        })
        return
    }
    gtag('consent', 'default', {
        ad_storage: 'denied', // Set to denied by default as this will set cookies
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        analytics_storage: 'denied', // Set to denied by default as this will set cookies
        functionality_storage: 'granted',
        personalization_storage: 'granted',
        security_storage: 'granted',
    })
}

export const addDataLayerConsent = () => {
    if (!isBrowser) return

    gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
        functionality_storage: 'granted',
        personalization_storage: 'granted',
        security_storage: 'granted',
        ad_user_data: 'granted',
        ad_personalization: 'granted',
    })
}

export const initateGTGInit = () => {
    if (!isBrowser) return
    gtag({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
        'gtm.uniqueEventId': 3,
    })
    gtag({ event: 'gtm.dom', 'gtm.uniqueEventId': 4 })

    gtag({ event: 'gtm.load', 'gtm.uniqueEventId': 5 })
}

export const dataLayergTag = async (info) => {
    if (!isBrowser) return
    const hashedMobile = await getHash(`${info?.country_code}-${info?.mobile_number}`);
    const hashedEmail = await getHash(info?.email_address);
    gtag(
        'event', 'complete_registration', {
        'userInfo': {
            'name': info?.student_name,
            'mobile': hashedMobile,
            'email': hashedEmail,
            'lead_id': info?.lead_id,
            'country': info?.country
            },
            'gtm.uniqueEventId': 16
        })
}

export const dataSubmissiongTag = async (info) => {
    if (!isBrowser) return

    const hashedMobile = await getHash(`${info?.country_code}-${info?.mobile_number}`);
    const hashedEmail = await getHash(info?.email_address);
    gtag(
        'event', 'Schedule',{
        'userInfo': {
            'name': info?.student_name,
            'mobile': hashedMobile,
            'email': hashedEmail,
            'lead_id': info?.lead_id,
            'country': info?.country
            },
            'gtm.uniqueEventId': 16
        })
}