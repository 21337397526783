export const SET_BATCH_DETAILS = 'SET_BATCH_DETAILS'
export const CLEAR_BOOKING_DETAILS = 'CLEAR_BOOKING_DETAILS'
export const SET_LOCATION_DETAILS = 'SET_LOCATION_DETAILS'
export const SET_LOCATION_LOADED = 'SET_LOCATION_LOADED'
export const CLOSE_VPN_POPUP = 'CLOSE_VPN_POPUP'
export const SET_COOKIE_POLICY = 'SET_COOKIE_POLICY'
export const SET_SHOW_COOKIE_POLICY_MODAL = 'SET_SHOW_COOKIE_POLICY_MODAL'
export const SET_IP_RESOLVED = 'SET_IP_RESOLVED'
export const MOE_INIT = 'TRACK_MOE_EVENT'
export const TRACK_MOE_EVENT = 'TRACK_MOE_EVENT'
export const DEC_12_BMD_OTP_GENERATION = 'clickedOtpGenerateBtn'
export const DEC_12_BMD_BTN_CLICKED = 'clickedBookDemoBtn'
export const DEC_12_BMD_OTP_GENERATION_FAILED = 'viewedOtpGenerationFailed'
export const DEC_12_BMD_OTP_GENERATION_SUCCESS = 'viewedOtpGenerationSuccess'
export const DEC_12_BMD_BOOKING_DETAILS = 'viewedBookingDetails'
export const DEC_12_BMD_OTP_VERIFICATION = 'viewedOtpVerification'
export const DEC_12_BMD_OTP_VERIFICATION_SUCCESS =
    'viewedOtpVerificationSuccess'
export const DEC_12_BMD_OTP_VERIFICATION_FAILED = 'viewedOtpVerificationFailed'
export const DEC_12_BMD_RESEND_TEXT_OTP_SUCCESS = 'viewedResendTextOtpSuccess'
export const DEC_12_BMD_RESEND_TEXT_OTP = 'clickedResendTextOtpBtn'
export const DEC_12_BMD_RESEND_TEXT_OTP_FAILED = 'viewedResendTextOtpFailed'
export const DEC_12_BMD_RESEND_VOICE_OTP = 'clickedResendVoiceOtpBtn'
export const DEC_12_BMD_GENERATE_VOICE_OTP = 'clickedGenerateVoiceOtpBtn'
export const DEC_12_BMD_GENERATE_VOICE_OTP_FAILED =
    'viewedGenerateVoiceOtpFailed'
export const DEC_12_BMD_GENERATE_VOICE_OTP_SUCCESS =
    'viewedGenerateVoiceOtpSuccess'
export const DEC_12_BMD_RESEND_EMAIL_OTP = 'clickedResendEmailOtpBtn'
export const DEC_12_BMD_GENERATE_EMAIL_OTP = 'clickedGenerateEmailOtpBtn'
export const DEC_12_BMD_GENERATE_EMAIL_OTP_FAILED =
    'viewedGenerateEmailOtpFailed'
export const DEC_12_BMD_GENERATE_EMAIL_OTP_SUCCESS =
    'viewedGenerateEmailOtpSuccess'
export const DEC_12_BMD_SLOT_SELECTION_IN_PROCESS = 'viewedSlotSelection'
export const DEC_12_BMD_SLOT_SELECTION_SHOWN_AVAILABLE_SLOT =
    'viewedAvailableSlot'
export const DEC_12_BMD_SLOT_SELECTION_FAILED = 'viewedSlotSelectionFailed'
export const DEC_12_BMD_SLOT_SELECTION_NOT_ABLE_TO_SHOWN_AVAILABLE_SLOT =
    'viewedNotAbleToShowAvailableSlot'
export const DEC_12_BMD_SLOT_SELECTION_SELECTED = 'clickedSlotSelection'
export const DEC_12_BMD_SLOT_BOOKING_FAILED = 'viewedSlotBookingFailed'
export const DEC_12_BMD_SLOT_BOOKING_SUCCESS = 'viewedSlotBookingSuccess'
export const DEC_12_BMD_LEAD_UPDATE_IN_PROGRESS = 'clickedLeadUpdateBtn'
export const DEC_12_BMD_LEAD_UPDATE_SUCCESS = 'viewedLeadUpdateSuccess'
export const DEC_12_BMD_LEAD_UPDATE_FAILED = 'viewedLeadUpdateFailed'
export const DEC_12_WEBINAR_PAGE = 'viewedWebinarPage'
export const DEC_12_BMD_TOPICS = 'viewedTopics'
export const DEC_12_BMD_TOPICS_SELECTED = 'clickedTopic'

export const MATHLETE_SCREEN_VIEW = 'MathleteScreenView'
export const CHESS_SCREEN_VIEW = 'ChessScreenView'
export const MATHLETE_ENROL_BUTTON_CLICK = 'MathleteEnrolButtonClick'
export const CHESS_ENROL_BUTTON_CLICK = 'ChessEnrolButtonClick'
export const CREATE_PAYMENT_ORDER_REQUEST = 'CreatePaymentOrderRequest'
export const GET_PAYMENT_ORDER_STATUS_REQUEST = 'GetPaymentOrderStatusRequest'
export const MATHLETE_VIEW_COURSE_DETAILS_CLICK = 'MathleteViewDetailsClick'

export const COMPETITION_SCREEN_VIEW = 'viewedCompetitionPage'
export const COMPETITION_APP_STORE_CLICK = 'clickedAvailableOnAppStore'
export const COMPETITION_PLAY_STORE_CLICK = 'clickedAvailableOnPlayStore'

export const COMPETITION_SYLLABUS_BANNER_VIEW = 'viewedSyllabusBanner'
export const COMPETITION_SYLLABUS_CLICK = 'clickedDownloadSyllabus'
export const COMPETITION_SYLLABUS_SUBIT_DETIALS_BUTTON_CLICK =
    'clickedSubmitDetails'
export const COMPETITION_REWARDS_CLICK = 'clickedViewMoreRewards'
export const COMPETITION_ROUND1_CLICK = 'clickedReadMoreRound1'
export const COMPETITION_ROUND2_CLICK = 'clickedReadMoreRound2'
export const COMPETITION_ROUND3_CLICK = 'clickedReadMoreRound3'
export const COMPETITION_ROUND4_CLICK = 'clickedReadMoreRound4'
