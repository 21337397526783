const isBrowser = typeof window !== 'undefined'
let url = 'https://api.bhanzu.com'
if (isBrowser) {
    url =
        window.location.hostname == 'expinfi.com' ||
        window.location.hostname == 'www.expinfi.com' ||
        window.location.hostname == 'www.bhanzu.com' ||
        window.location.hostname == 'bhanzu.com'
            ? 'https://api.bhanzu.com'
            : window.location.hostname == 'stage.bhanzu.com'
            ? 'https://api.stage.bhanzu.com'
            : 'https://api.dev.bhanzu.com'
} else {
    url = 'https://api.dev.bhanzu.com'
}

const ApiConfig = {
    baseUrl: url,
    slotBooking: {
        getOtp: '/otp/v2/get-otp',
        resendOtp: '/otp/v2/resend-otp',
        verifyOtp: '/otp/v2/verify-otp',
        getSlots: '/demo-slots/v2/get-suitable-slots',
        selectSlot: '/demo-slots/v2/select-slot',
        updateLead: '/demo-slot/v1/update-lead',
        cancelSlot: '/demo-slots/v2/cancel-slot',
        sendOtpOnCall: '/otp/v2/send-voice-otp',
    },
    updateSalesforce: {
        updateLead: '/demo-slot/v1/create-salesforce-lead',
    },
    common: {
        ipinfo: '/ipinfo/',
    },
    courses: {
        getAll: '/lms/v1/courses/homepage',
    },
    webinar: {
        postWebinarDataInDb: '/webinar/v1/book-webinar',
        updateWebinarDataInDb: '/webinar/v1/confirm-registration',
        getWebinarDetailsfromWebinarId: '/webinar/v1/get-webinar/',
    },
    hobbyClass: {
        getHobbyClassConfigIdDetails: '/hcms/v1/class/get/sessions',
        markStudentAttendance: '/hcms/v1/student/attend',
        getUpcomingHobbyClassWeb: '/hcms/v1/web/class/get/filtered',
    },
    streams: {
        getAllBatchesByVariantId: '/streams/v1/variant/batch',
        getPricingByVariantId: '/user/v1/get-pricing',
    },
    payments: {
        createPaymentOrder: '/payments/orders/v1/create',
        getOrderStatus: '/payments/orders/v1/status',
    },
    mathleteTrial: {
        enrolNow: '/user/v1/trial_enroll_streams',
        verifyOtp: '/otp/v2/get-otp',
    },
}

export default ApiConfig
