import { nanoid } from 'nanoid'
import UtilService from '../services/utilServices'

function getCurrentSessionOrElse(storagekey, key, predicate) {
    const stored = window[storagekey].getItem(key)
    if (!stored) {
        return predicate(key)
    }
    return stored;
}

export function CurrentSession() {
    const key = 'b_sid'
    const value = getCurrentSessionOrElse('sessionStorage', 'b_sid', () => {
        return nanoid()
    });
    const domain = window.location.hostname;
    sessionStorage.setItem(key, value)
    UtilService.setCookie(key, value, 2, domain);
    return value
}

export function GlobalSession() {
    const key = 'b_gid'
    const value = getCurrentSessionOrElse('localStorage', key, () => {
        const cookieGID = UtilService.getCookie(key)
        return cookieGID || nanoid()
    });
    const domain = window.location.hostname;
    localStorage.setItem(key, value)
    UtilService.setCookie(key, value, 4000, domain);
    return value
}
