import * as actionTypes from '../types'

export const initialState = { bookingDetails: {} }

export const BookDemoReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_BATCH_DETAILS:
            return {
                ...state,
                bookingDetails: {
                    ...state.bookingDetails,
                    ...action.payload.bookingDetails,
                },
                ...action.payload,
            }
        case actionTypes.CLEAR_BOOKING_DETAILS:
            return {
                ...state,
                bookingDetails: {},
            }

        default:
            return { ...state }
    }
}
