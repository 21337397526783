import axios from 'axios'
import countryCodes from 'country-codes-list'
import ApiConfig from '../config/apiConfig'
import {
    CookieVerificationValues,
    websiteCookies,
} from '../config/cookieConfig'

let utilsFunctionCallStack = []
const addToUtilsCallStack = (fn) => {
    utilsFunctionCallStack.push(fn)
}
const isBrowser = typeof window !== 'undefined'
// Only run on the client side
// Listen for cookie policy accepted event and execute all the functions in the call stack when the event is triggered
// This is to ensure that the functions that are dependent on cookie policy acceptance are executed only after the cookie policy is accepted
isBrowser &&
    window.addEventListener('cookie-policy-accepted', () => {
        utilsFunctionCallStack.forEach((fn) => fn())
        utilsFunctionCallStack = []
    })
const sessionIpInfo = {
    get: () => {
        const storedIpInfo = sessionStorage.getItem('IP_INFO')
        if (storedIpInfo) {
            return JSON.parse(storedIpInfo)
        }
        return null
    },
    set: (ipInfo) => {
        sessionStorage.setItem('IP_INFO', JSON.stringify(ipInfo))
    },
}

const UtilService = {
    getCountryList: function (type) {
        const countryExtn = countryCodes.customList(
            'countryCode',
            '+{countryCallingCode}'
        )
        const countryNames = countryCodes.customList(
            'countryCode',
            '+{countryCallingCode}'
        )
        return type === 'name' ? countryNames : countryExtn
    },
    getCountry: function (payload) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve('resolved')
            }, 2000)
        })
    },
    getUUID: function () {
        return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(
            /[x]/g,
            function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c == 'x' ? r : (r & 0x3) | 0x8
                return v.toString(16)
            }
        )
    },
    getIpInfo: async function () {
        const storedIpInfo = sessionIpInfo.get()
        if (storedIpInfo) {
            return storedIpInfo
        }
        let res
        try {
            res = await axios.get('https://ipinfo.io/?token=3db1c36c04e84a', {
                withCredentials: false,
            })
        } catch (error) {
            res = await axios.get(
                ApiConfig.baseUrl + ApiConfig.common.ipinfo + this.getUUID(),
                { withCredentials: false }
            )
        }
        sessionIpInfo.set(res.data)
        return await res.data
    },
    setCookie: function (name, value, days, domain) {
        if (!domain) {
            domain = window.location.hostname
        }
        const cookiePolicyValue = this.getCookie(websiteCookies.cookiePolicy)
        if (
            cookiePolicyValue !== CookieVerificationValues.accept &&
            name != websiteCookies.cookiePolicy
        ) {
            addToUtilsCallStack(() => {
                this.setCookie(name, value, days, domain)
            })
            return
        }
        var expires = ''
        if (days) {
            var date = new Date()
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
            expires = '; expires=' + date.toUTCString()
        }
        if (domain && !(domain === 'localhost' || domain === '127.0.0.1')) {
            document.cookie =
                name +
                '=' +
                (value || '') +
                expires +
                '; domain=.' +
                domain +
                '; path=/'
        } else {
            document.cookie = name + '=' + (value || '') + expires + '; path=/'
        }
    },
    getCookie: function (name) {
        var nameEQ = name + '='
        var ca = document.cookie.split(';')
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i]
            while (c.charAt(0) == ' ') c = c.substring(1, c.length)
            if (c.indexOf(nameEQ) == 0)
                return c.substring(nameEQ.length, c.length)
        }
        return null
    },
    getQueryParams: function (queryString) {
        queryString = queryString.split('?')[1] ? queryString.split('?')[1] : ''
        const parts = queryString.split('&')
        return parts.reduce((acc, p) => {
            let keyValuePair = p.split('=')
            let key = keyValuePair[0]
            let value = keyValuePair[1]
            value = decodeURIComponent(value)
            value = value.replace(/\+/g, ' ')
            value = value.split('#')[0]
            acc[key] = value
            return acc
        }, {})
    },
    getCountries: function () {
        const countries = {
            AU: 'Australia',
            CA: 'Canada',
            IN: 'India',
            MY: 'Malaysia',
            NZ: 'New Zealand',
            SG: 'Singapore',
            AE: 'United Arab Emirates',
            US: 'United States',
            TH: 'Thailand',
            HK: 'Hong Kong',
            PH: 'Philippines',
            ID: 'Indonesia',
            QA: 'Qatar',
            OM: 'Oman',
            KW: 'Kuwait',
            BH: 'Bahrain',
            SA: 'Saudi Arabia',
            ZA: 'South Africa',
        }
        return countries
    },
    getTimeZones: function () {
        const timezones = {
            TH: ['Asia/Bangkok'],
            HK: ['Asia/Hong_Kong'],
            PH: ['Asia/Manila'],
            ID: ['Asia/Jakarta', 'Asia/Jayapura', 'Asia/Makassar'],
            QA: ['Asia/Qatar'],
            OM: ['Asia/Muscat'],
            KW: ['Asia/Kuwait'],
            BH: ['Asia/Bahrain'],
            SA: ['Asia/Riyadh'],
            MY: ['Asia/Kuala_Lumpur'],
            SG: ['Asia/Singapore'],
            ZA: ['Africa/Johannesburg'],
            AU: [
                'Australia/Brisbane',
                'Australia/Darwin',
                'Australia/Melbourne',
                'Australia/Perth',
                'Australia/Sydney',
            ],
            CA: [
                'America/Atikokan',
                'America/Blanc-Sablon',
                'America/Cambridge_Bay',
                'America/Creston',
                'America/Dawson',
                'America/Dawson_Creek',
                'America/Edmonton',
                'America/Fort_Nelson',
                'America/Glace_Bay',
                'America/Goose_Bay',
                'America/Halifax',
                'America/Inuvik',
                'America/Iqaluit',
                'America/Moncton',
                'America/Nipigon',
                'America/Pangnirtung',
                'America/Rainy_River',
                'America/Rankin_Inlet',
                'America/Regina',
                'America/Resolute',
                'America/St_Johns',
                'America/Swift_Current',
                'America/Thunder_Bay',
                'America/Toronto',
                'America/Vancouver',
                'America/Whitehorse',
                'America/Winnipeg',
                'America/Yellowknife',
            ],
            IN: ['Asia/Kolkata'],
            NZ: ['Pacific/Auckland', 'Pacific/Chatham'],
            US: [
                'America/Adak',
                'America/Anchorage',
                'America/Boise',
                'America/Chicago',
                'America/Denver',
                'America/Detroit',
                'America/Indiana/Indianapolis',
                'America/Indiana/Knox',
                'America/Indiana/Marengo',
                'America/Indiana/Petersburg',
                'America/Indiana/Tell_City',
                'America/Indiana/Vevay',
                'America/Indiana/Vincennes',
                'America/Indiana/Winamac',
                'America/Juneau',
                'America/Kentucky/Louisville',
                'America/Kentucky/Monticello',
                'America/Los_Angeles',
                'America/Menominee',
                'America/Metlakatla',
                'America/New_York',
                'America/Nome',
                'America/North_Dakota/Beulah',
                'America/North_Dakota/Center',
                'America/North_Dakota/New_Salem',
                'America/Phoenix',
                'America/Sitka',
                'America/Yakutat',
                'Pacific/Honolulu',
            ],
            AE: ['Asia/Dubai'],
        }
        return timezones
    },

    getCookieExpireTime: async (name) => {
        try {
            const cookie = await window.cookieStore.get(name)
            if (cookie) {
                return cookie.expires
            }
        } catch (e) {
            console.log(e)
        }
        return null
    },
    clearAllCookies: () => {
        var cookies = document.cookie.split(';')

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i]
            var eqPos = cookie.indexOf('=')
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
            document.cookie =
                name +
                '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=' +
                window.location.hostname
        }
    },
}

export default UtilService
