import React, { createContext, useCallback, useMemo, useReducer } from 'react'
import {
    BookDemoReducer,
    initialState as bookDemoInitialState,
} from './reducers/BookDemoReducer'
import LocationDetailsReducer, {
    initialState as locationDetailsInitialState,
} from './reducers/LocationDetailsReducer'
import MoengageReducer, {
    initialState as moenageInitialState,
} from './reducers/MoengageReducer'
import CookiePolicyReducer, {
    initialState as cookiePolicyInitialState,
} from './reducers/CookiePolicyReducer'

const combineDispatch =
    (...dispatches) =>
    (action) => {
        dispatches.forEach((dispatch) => dispatch(action))
    }

const initialState = {
    ...bookDemoInitialState,
    ...locationDetailsInitialState,
}
export let combinedDispatchExport

const Store = ({ children }) => {
    const [bookDemoState, dispatchBookDemo] = useReducer(
        BookDemoReducer,
        bookDemoInitialState
    )
    const [locationDetailsState, dispatchLocationDetails] = useReducer(
        LocationDetailsReducer,
        locationDetailsInitialState
    )

    const [moenageState, dispatchMoenageEvent] = useReducer(
        MoengageReducer,
        moenageInitialState
    )

    const [cookePolicyState, dispatchCookiePolicy] = useReducer(
        CookiePolicyReducer,
        cookiePolicyInitialState
    )

    const combinedDispatch = useCallback(
        combineDispatch(
            dispatchBookDemo,
            dispatchLocationDetails,
            dispatchMoenageEvent,
            dispatchCookiePolicy
        ),
        [
            dispatchBookDemo,
            dispatchLocationDetails,
            dispatchMoenageEvent,
            dispatchCookiePolicy,
        ]
    )
    combinedDispatchExport = combinedDispatch
    const combinedState = useMemo(
        () => ({
            ...bookDemoState,
            ...locationDetailsState,
            ...moenageState,
            ...cookePolicyState,
        }),
        [bookDemoState, locationDetailsState, moenageState, cookePolicyState]
    )

    return (
        <Context.Provider value={[combinedState, combinedDispatch]}>
            {children}
        </Context.Provider>
    )
}

export const Context = createContext(initialState)

export default Store
