/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import Store from './src/store';
import GoogleReCaptcha from './src/reCaptchaProvider';
import { addDataLayerRestriction } from './src/util/addDataLayer';
addDataLayerRestriction();
export const wrapRootElement = ({ element }) => {
  return (
    <>
      <GoogleReCaptcha>
        <Store>{element}</Store>
      </GoogleReCaptcha>
      <noscript>
        <iframe
          src={'https://www.googletagmanager.com/ns.html?id=' + process.env.GTM_ID}
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}
    </>
  );
};
