import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const isBrowser = typeof window !== 'undefined'
let recaptchaDiv
if (isBrowser) {
    recaptchaDiv = document.createElement('div')
    recaptchaDiv.id = 'recaptcha'
    recaptchaDiv.style.display = 'none'
    document.body.appendChild(recaptchaDiv)
}
export default function index({ children }) {
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey="6LffJBslAAAAAO0ib9q4lCHk-oqlbjE-LwxVb7vh"
            useEnterprise={true}
            scriptProps={{
                async: false, // optional, default to false,
                defer: false, // optional, default to false
                appendTo: 'head', // optional, default to "head", can be "head" or "body",
                nonce: undefined, // optional, default undefined
            }}
            container={
                recaptchaDiv
                    ? {
                          // optional to render inside custom element
                          element: recaptchaDiv,
                          parameters: {
                              badge: 'inline', // optional, default undefined
                              theme: 'dark', // optional, default undefined
                          },
                      }
                    : undefined
            }
        >
            {children}
        </GoogleReCaptchaProvider>
    )
}
